tui-radio-block.custom {
    width: 100%;

    label.t-wrapper {
        padding: 0px !important;

        .t-label {
            justify-content: flex-start !important;
        }
    }
    
    [tuiWrapper]:after {
        border: none;
    }

    [tuiWrapper][data-appearance='whiteblock-active']:after {
        content: '✔';
        padding-top: 6px;
        top: initial;
        bottom: initial;
        left: initial;
        right: initial;
    }
}