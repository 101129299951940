:root {
    --wf-font-family: 'Manrope';
    --wf-font-text-xxxl: normal 1.5rem/2.25rem var(--wf-font-family); // 24px/36px
    --wf-font-text-xxl: normal 1.25rem/2.25rem var(--wf-font-family); // 20px/36px
    --wf-font-text-xl: normal 1.1875rem/1.75rem var(--wf-font-family);  // 19px/28px
    --wf-font-text-l: normal 1.0625rem/1.5rem var(--wf-font-family);    // 17px/24px
    --wf-font-text-l-2: normal 1.0625rem/1.25rem var(--wf-font-family); // 17px/20px
    --wf-font-text-m: normal 1rem/1.5rem var(--wf-font-family);         // 16px/24px
    --wf-font-text-m-2: normal 1rem/1.25rem var(--wf-font-family);      // 16px/20px
    --wf-font-text-s: normal .875/1.25rem var(--wf-font-family);    // 13px/20px
    --wf-font-text-s-2: normal .8125rem/1rem var(--wf-font-family);     // 13px/16px
    --wf-font-text-xs: normal .6875rem/1rem var(--wf-font-family);      // 11px/16px
    --wf-font-text-xxs: normal .5rem/.6875rem var(--wf-font-family);    // 8px/11px
}