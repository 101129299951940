@import '@taiga-ui/core/styles/taiga-ui-local.scss';

button[type=button] .t-wrapper[tuiWrapper] {
    &:focus-visible {
        outline: none;
    }
}

[data-size=xs][appearance='custom'] .t-wrapper[tuiWrapper][data-appearance='custom'] {
    .t-content {
        font: var(--wf-font-text-xs);
    }
}

[data-size=s][appearance='custom'] .t-wrapper[tuiWrapper][data-appearance='custom'] {
    padding: 16px;
}

[data-size=m][appearance='footer-btn'] .t-wrapper[tuiWrapper][data-appearance='footer-btn'] {
    height: 40px;

    &:after {
        all: unset;
    }
}

[data-size=m][tuiIconButton][appearance='footer-btn'] {
    width: 40px !important;

    .t-wrapper[tuiWrapper][data-appearance='footer-btn'] {
        width: 40px;
    }
}

[data-size=m][tuiButton][appearance='primary'] {
    font: var(--tui-font-text-s);
}

[tuiWrapper][data-appearance='custom'],
[tuiWrapper][data-appearance='footer-btn'] {
    background: var(--wf-color-blue);
    color: var(--wf-color-white);
    border-radius: 30px;
    border: none;
    font: var(--wf-font-text-m);
 
    @include wrapper-hover{
        background: var(--wf-color-blue);
    };
 
    @include wrapper-active{
        background:  var(--wf-color-blue);
    }
 
    @include  wrapper-disabled{
        background:  var(--wf-color-blue);
    }
}

[tuiWrapper][data-appearance='custom-secondary'] {
    background: var(--wf-color-card);
    color: var(--wf-color-black);
    border-radius: 30px;
    border: none;
    font: var(--wf-font-text-s);
 
    @include wrapper-hover{
        background: var(--wf-color-card);
        color: var(--wf-color-black);
    };
 
    @include wrapper-active{
        background: var(--wf-color-card);
        color: var(--wf-color-black);
    }
 
    @include  wrapper-disabled{
        background: var(--wf-color-card);
        color: var(--wf-color-black);
    }
}

[data-size=m][appearance='custom-secondary'] .t-wrapper[tuiWrapper][data-appearance='custom-secondary'] {
    height: 40px;
    padding: 0 var(--wf-space-4);
    white-space: nowrap;

    .t-content {
        font-size: 13px;
        font-weight: 400;
    }

    &:after {
        all: unset;
    }
}