@import 'assets/styles/colors.scss';
@import 'assets/styles/spaces.scss';
@import 'assets/styles/radius.scss';
@import 'assets/styles/font.scss';

// components
@import 'assets/styles/components/virtual-scroll-view-port.scss';
@import 'assets/styles/components/card.scss';
@import 'assets/styles/components/button.scss';
@import 'assets/styles/components/tabs.scss';
@import 'assets/styles/components/label.scss';
@import 'assets/styles/components/radio-block.scss';
@import 'assets/styles/breakpoint.scss';
@import 'assets/styles/components/tag.scss';
@import 'assets/styles/components/pagination.scss';
@import 'assets/styles/components/input.scss';
@import 'assets/styles/components/push.scss';
@import 'assets/styles/components/dialog.scss';
@import 'assets/styles/components/text-area.scss';
@import 'assets/styles/components/tooltip.scss';

* {
    box-sizing: border-box;
    margin: 0;
}

html {
    font-size: 16px;
}

body {
    // overflow: hidden;
    font: var(--wf-font-text-m);
    -webkit-font-smoothing: antialiased;
}

:root {
    // Override taiga variables
    --tui-primary: var(--wf-color-blue) !important;
    --tui-primary-hover: var(--wf-color-blue) !important;
    --tui-primary-active: var(--wf-color-blue) !important;

    /* breakpoint variables */
    --desktop-screen: 1280px;
    --tablet-screen: 601px;
    --mobile-screen: 360px;
}

/* Hide the clear button in search input */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}


/* Images placeholder  TODO: move to another place*/
@keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

.g-skeleton {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 400%;
    animation: 1.5s shine linear infinite;
    padding: 0 50%;
}