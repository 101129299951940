tui-hint {
    background-color: white !important;
    color: #1B1F3B !important;
    border: 1px solid rgb(215, 215, 215);

    &::before {
        border-left: 1px solid rgb(215, 215, 215);
        border-top: 1px solid rgb(215, 215, 215);
    }
   
}