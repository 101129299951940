tui-tag[data-status=primary], tui-tag[data-status=custom], a[tuitag][data-status=custom] {
    &[data-size=m], &[data-size=s] {
        border-radius: 30px;
    }

    &[data-size=m] .t-tag{
        height: var(--tui-height-s) !important;
        padding: 0 0.75rem !important;
    }
}

tui-tag[data-status=custom] {
    background-color: var(--wf-color-card);
}