:root {
    --wf-color-white: #fff;
    --wf-color-black: #000000;
    --wf-color-card: #F6F6F6;
    --wf-color-tag: #EBEBEB;
    --wf-color-blue: #0a84ff;
    --wf-color-blue-2: rgba(10, 132, 255, 0.20);
    --wf-color-accent: #C9ADFF;
    --wf-text-color-accent: #5600FF;
    --wf-color-label: rgba(0, 0, 0, 0.65);
    --wf-color-base: #D7D7D7;
}