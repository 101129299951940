tui-tabs.tabs {
    box-shadow: none;
    margin-top: var(--wf-space-3);
    overflow: hidden;
    flex-wrap: wrap;
    gap: var(--wf-space-3);
    height: auto;

    button {
        background-color: var(--wf-color-tag);
        color: var(--tui-text-01);
        border-radius: var(--wf-space-5);
        padding: 0px var(--wf-space-5);
        font-size: 13px;
        font-weight: 400;
        margin-left: 0px;
        height: 32px;

        &._active {
            background-color: var(--wf-color-blue);
            color: white;
        }

        &:not([data-mode])._active {
            color: white;
        }

    }
}

tui-tabs.legend-tabs {
    box-shadow: none;
    box-shadow: none;
    height: auto;
    display: flex;
    flex-flow: column;
    align-items: baseline;

    button {
        background: none;
        font-size: 13px;
        font-weight: 400;
        margin-right: var(--wf-space-3);
        margin-left: 0px;
        color: black;

        &._active {
            background: none;
            color: black;
        }

        &:not([data-mode])._active {
            color: black;
        }

    }
}