tui-input {
    &:focus-visible {
        outline: none;

        [tuiWrapper][data-appearance='textfield'][data-appearance='textfield']:after {
            all: unset;
        }
    }

    [tuiWrapper][data-appearance='textfield'][data-appearance='textfield']:after {
        all: unset;
    }

    [tuiWrapper][data-appearance='textfield'],
    [tuiWrapper][data-appearance='textfield']:hover:not(._no-hover),
    [tuiWrapper][data-appearance='textfield'][data-state='hover'] {
        box-shadow: none;
    }
}