tui-push.cookie {
    width: 400px;
    font-size: var(--wf-font-text-s);

    .t-top {
        display: none;
    }
    .t-content {
        -webkit-line-clamp: 7;
    }
}